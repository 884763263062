<template>
     <v-container fluid>
    <!-- class="grey lighten-5">  -->
    <header-app></header-app>
    <!--   RINCON GOURMET -->
    <!-- class="grey lighten-5">  -->

    <!--HEADER-->
    
    <!--HEADER FIN-->
    <complejo-home :complejo=complejo :colorMenu=colorMenu :items1=items1 :items2=items2 :items3=items3  ></complejo-home>
     </v-container>

</template>
<script>
import headerApp from '../components/headerApp.vue'
import complejoHome from '../components/complejohome'
export default {
  components:{complejoHome , headerApp},
  data() {
    return {
      complejo:"rde",
      colorMenu:"#002A53",
     items1: [     
        {
          texto1: "Nuestra",
          texto2: "Carta",
          icono: "mdi-silverware",
          destino: "cartarde"
        },
        {
          texto1: "Rservar",
          texto2: "Mesa",
          icono: "mdi-clock-outline",
          destino: "reservarmesarde"
        },
        {
          texto1: "Menú",
          texto2: "del día",
          icono: "mdi-room-service-outline",
          destino: "roomservicerde"
        }
      ],
      items2:[{
           texto1: "Normas del Complejo",
           icono: "mdi-book-open-page-variant",
           destino: "normasrde"
           },
           {
             texto1: "Numeros Importantes",             
           icono: "mdi-phone-classic",
           destino: "telefonosrde"
           }
      ],
      items3:[{        
           texto1: "Servicios",
           texto2: "del complejo",
           icono: "mdi-pool",
           destino: "serviciosrde"
           },
           {
             texto1: "Actividades",
             texto2: "recreativas del día" ,            
           icono: "mdi-basketball",
           destino: "actividadesrde"
           }
      ],
      miniVariant: false,
      title: "Rincon Club",
      activeBtn: null,
      activeBtn2: 0
    }
  },
  created: function() {
    document.title = "Rincon del Este";
  }
}
</script>
<style scoped>

</style>
